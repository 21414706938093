<template>
  <div class="k-9sptci" v-loading="loading">
    <!-- 标题 -->
    <div class="k-9sptci-header" style="">
      <h4 style="margin: 10px ; ">
        {{ titleName }}·{{titleType}}
      </h4>
      <div>
        <el-button
            v-if="type_id ==='qualifier'"
            type="primary"
            size="medium"
            @click="handlePublish">
          发布
        </el-button>
        <el-button
            v-if="type_id ==='qualifier'"
            type="primary"
            size="medium"
            @click="handleExportGroup">
          导出分组信息
        </el-button>
        <el-button
            v-if="type_id ==='qualifier'"
            type="primary"
            size="medium"
            @click="handleExportReplaceGroup">
          导出替补信息
        </el-button>
      </div>
    </div>
    <el-form
        :inline="true"
        class="k-9sptci-header"
        :model="searchForm"
        ref="searchForm"
    >
      <!-- <el-form-item>
          <el-button type="success" @click="searchClick">查询</el-button>
        </el-form-item> -->

<!--      <el-form-item>-->
<!--        <el-button type="primary" @click="add">新增</el-button>-->
<!--      </el-form-item>-->
    </el-form>
    <!-- s4预选赛 -->
    <el-tabs
        style="flex: 1"
        v-if="match_type.includes('match_s') && type_id === 'qualifier'|| match_type.includes('match_s') && type_id === 'regular'"
        v-model="searchForm.stage"
        type="card"
        @tab-click="handleClick"
    >
      <el-tab-pane
          v-for="(item, index) in weeks_qualifier"
          :label="item.label"
          :name="String(item.name)"
          :key="index"
      ></el-tab-pane>
    </el-tabs>
    <!-- s4常规赛 -->
    <el-tabs
        style="flex: 1"
        v-if="match_type.includes('match_s') && type_id === 'regular'"
        v-model="searchForm.stage"
        type="card"
        @tab-click="handleClick"
    >
      <el-tab-pane
          v-for="(item, index) in weeks"
          :label="item.label"
          :name="String(item.name)"
          :key="index"
      ></el-tab-pane>
    </el-tabs>
    <el-table :data="dataTable" style="margin-top:20px" border>
      <template v-for="item in dataList">
        <el-table-column
            v-if="item.prop === 'banner_url'"
            :key="item._id"
            :prop="item.prop"
            :type="item.type"
            :label="item.label"
            :width="item.width"
            :formatter="item.formatter"
        >
          <template slot-scope="scope">
            <img
                :src="scope.row.banner_url"
                style="width: 200px; height: 100px"
            />
          </template>
        </el-table-column>
        <el-table-column
            v-else
            :key="item._id"
            :prop="item.prop"
            :type="item.type"
            :label="item.label"
            :width="item.width"
            :formatter="item.formatter"
        >
        </el-table-column>
      </template>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button @click="handleEdit(scope.row)" style="margin-left: 10px"
          >编辑</el-button
          >
          <el-button
              style="margin-left: 10px"
              @click.native.stop="handleDelete(scope.row._id)"
          >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <div>
      <el-pagination
          @current-change="handleQuery"
          :current-page.sync="pager.pageNum"
          layout="prev, pager, next"
          :total="pager.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { randomStr } from "@/utils/randomStr";
import { MessageBox } from "element-ui";
import {matchType, matchTypeMap} from "./public.js/toolbarOptions";
export default {
  name: "s1_banners",
  computed: {
    ...mapState("match", ["match_types"]),
    titleName() {
      return matchTypeMap[this.match_type] || "默认标题";
    },
    titleType() {
      return matchType[this.type_id] || "";
    },
  },
  data() {
    return {
      tempGroupData: [],
      weeks_qualifier: [{ label: "第1周", name: '1' }],
      weeks: [{ label: "换组积分赛第1轮", name: '1' }],
      options: [],
      pager: {
        total: 10,
        pageNum: 1,
      },
      ids: [],
      type_id: "s1",
      match_id: "",
      match_type: "",
      season_id: "",
      loading: false,
      searchForm: {
        stage: "1",
      },
      dataTable: [],
      dataList: [
        {
          prop: "group_name",
          label: "分组名称",
          width: 300,
        },

        {
          prop: "sectInfo",
          label: "门派",
          width: 900,
          formatter: (row) => {
            const names = row.sectInfo.map((item) => item.sect_name).join(",");
            return names;
          },
        },
      ],
      groupVisible: false,
      groupData: {
        view_type: "add",
        _id: "",
        group_name: "",
        team_info: [],
      },
      match: false,
    };
  },
  methods: {
    // 获取每周赛事,tab
    async get_stage_list() {
      const res = await this.$http.match.s4_stage_list({
        match_id: this.match_id,
        season_id: this.season_id,
        pageNum: "1",
        pageSize: "10",
      });

      if (this.weeks_qualifier.length < res.data.data.length) {
        for (let i = 1; i < res.data.data.length; i++) {
          this.weeks_qualifier.push({
            label: `第${i + 1}周`,
            name: `${i + 1}`,
          });
        }
      }
      if (this.weeks.length < res.data.data.length) {
        for (let i = 1; i < res.data.data.length; i++) {
          this.weeks.push({ label: `换组积分赛第${i+1}轮`, name: `${i + 1}` });
        }
      }
      // if (res.data.pager) {
      //   this.pager = res.data.pager
      // }
    },
    //点击tab
    handleClick(tab) {
      console.log(tab.name, this.searchForm);
      this.handleQuery();
    },
    async handlePublish() {
      try {
        // await MessageBox.confirm("是否发布?", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // });

        const { code } = await this.$http.match.s4_publish_stage(
          {
            match_id: this.match_id,
            season_id: this.season_id,
            stage: this.searchForm.stage,
          }
        );
        if (code === 0) {
          this.$message({
            type: "success",
            message: "发布成功",
          });
          await this.handleQuery();
        }
      } catch (error) {
        console.log(error);
        this.$message({
          type: "error",
          message: "发布失败，请稍后重试！",
        });
      }
    },
    /**
     * 导出门派分组资料
     */
    async handleExportGroup () {
      const requestParams = {
        match_id: this.match_id,
        season_id: this.season_id,
        stage: this.searchForm.stage,
      }

      this.$http.download({
        url: "/admin/match/new_match/export_stage_group",
        method: "post",
        data: requestParams,
      });
    },

    /**
     * 导出门派替补分组资料
     */
    async handleExportReplaceGroup () {
      const requestParams = {
        match_id: this.match_id,
        season_id: this.season_id,
        stage: this.searchForm.stage,
      }

      this.$http.download({
        url: "/admin/match/new_match/export_stage_replace",
        method: "post",
        data: requestParams,
      });
    },

    // 添加初始化
    add() {
      this.groupData.view_type = "add";
      this.groupData._id = "";
      this.groupData.group_name = "";
      this.groupData.team_info = "";
      this.options = [];
      this.removeTabByPath(`/match/subject/gruopManage`);
      this.$router.push({
        path: `/match/subject/gruopManage`,
        query: {
          match_id: this.match_id,
          season_id: this.season_id,
          type_id: this.type_id,
          match_type: this.match_type,
          isAdd: "add",
        },
      });
      // this.$nextTick(() => {
      //   this.$refs["groupData"].clearValidate();
      // });
    },
    //编辑赋值
    handleEdit(row) {
      this.groupData.team_info = [];
      this.groupData.view_type = "edit";
      this.groupData._id = row._id;
      //分组名
      this.groupData.group_name = row.group_name;
      //拿到显示的参赛门派
      this.options = row.sectInfo;
      //拿到数组id
      if (row.sectInfo.length != 0) {
        row.sectInfo.forEach((item) => {
          this.groupData.team_info.push(item.sect_id);
        });
      }
      this.tempGroupData = row.sectInfo;
      // this.groupVisible = true;
      this.removeTabByPath(`/match/subject/gruopManage`);
      this.$router.push({
        path: `/match/subject/gruopManage`,
        query: {
          _id: row._id,
          match_id: this.match_id,
          group_id: row._id,
          season_id: this.season_id,
          type_id: this.type_id,
          group_name: row.group_name,
          match_type: this.match_type,
          stage: this.searchForm.stage,
          isAdd: "edit",
        },
      });
    },

    //删除
    async handleDelete(_id) {
      try {
        await MessageBox.confirm("是否删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        });

        const { code } = await this.$http.match_subject.delete_season_group_new(
            {
              _id: _id,
            }
        );
        if (code === 0) {
          this.$message({
            type: "success",
            message: "操作成功",
          });
          this.handleQuery();
        }
      } catch (error) {
        console.log(error);
      }
    },
    //查询按钮
    searchClick() {
      this.$refs.searchForm.validate((v) => {});
      this.handleQuery();
    },
    //查询列表
    async handleQuery() {
      const { pageNum } = this.pager;
      this.loading = true;
      //请求接口
      const {
        data,
        errorCode,
      } = await this.$http.match_subject.season_group_list_new({
        pageNum,
        season_id: this.season_id,
        stage: this.searchForm.stage,
      });
      this.loading = false;
      if (errorCode != "0000") {
        return;
      }
      this.dataTable = data.data;
      this.pager = data.pager;
    },
    //tab的变化
    ...mapActions("tabs", [
      "handleTabsEdit",
      "addTabs",
      "handleTabsClick",
      "removeTab",
      "removeTabByPath",
    ]),
  },
  async activated() {
    this.type_id = this.$route.query.type_id;
    this.match_id = this.$route.query.match_id;
    this.season_id = this.$route.query.season_id;
    this.match_type = this.$route.query.match_type;

    //查询列表
    this.handleQuery();
    if (this.$route.query.match_type.includes('match_s') ) {
      await this.get_stage_list()
    }
  },
  destroyed() {},
};
</script>
<style lang="less" scoped>
.el-select {
  width: 100%;
}
.k-9sptci-header {
  background-color: #fff;
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  .el-form-item {
    margin-bottom: 0;
  }
}
.k-9sptci-footer {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

/deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 200px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

/deep/ .avatar-uploader-icon {
  font-size: 38px;
  color: rgb(0, 153, 255);
  text-align: center;
}

.avatar {
  width: 200px;
  height: 100px;
}
</style>
