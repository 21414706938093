export const SECT_MATCHES = Object.freeze([
    'match_s1',
    'match_s2',
    'match_s3',
]);

export const NEW_SECT_MATCHES = Object.freeze([
    'match_s4',
    'match_s5',
]);
